var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h2',{staticClass:"mb-2",staticStyle:{"padding-bottom":"5px","padding-top":"9px"}},[_vm._v("My Profile")])])],1),_c('validation-observer',{ref:"profile_list",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"profile-frm",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"text-right mb-2"},[(!_vm.isEditing)?_c('b-button',{staticClass:"btn-200 mr-10",attrs:{"type":"button","variant":"primary"},on:{"click":_vm.openChangePassword}},[_vm._v("Change Password")]):_vm._e(),(!_vm.isEditing)?_c('b-button',{staticClass:"btn-df white-color",attrs:{"type":"button","variant":"primary"},on:{"click":_vm.editAction}},[_vm._v("Edit")]):(_vm.isEditing)?_c('b-button',{staticClass:"btn-df size-18 black",staticStyle:{"box-shadow":"0px 0px 4px rgba(0, 0, 0, 0.25)","margin-right":"10px"},attrs:{"type":"button","variant":"basic"},on:{"click":_vm.cancelPass}},[_vm._v("Cancel")]):_vm._e(),(_vm.isEditing)?_c('b-button',{staticClass:"btn-df size-18 black",attrs:{"type":"button","variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save ")]):_vm._e()],1),_c('div',{staticClass:"pb-2 mb-10",staticStyle:{"border-top":"1px solid #000000B2"}}),_c('div',{staticClass:"frm-basic size-16 black-child d-block"},[_c('b-row',{staticStyle:{"width":"100%","margin":"0"}},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"ID","label-cols-xl":"3","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"placeholder":"Enter ID","readonly":""},model:{value:(_vm.form.id),callback:function ($$v) {_vm.$set(_vm.form, "id", $$v)},expression:"form.id"}})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Salutation","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Salutation","vid":"salutation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"black-child-im",attrs:{"label":"title","options":_vm.salutation,"placeholder":"Select an option","reduce":function (option) { return option.value; },"disabled":_vm.isDisabled,"id":_vm.disableField,"formatter":_vm.length100},model:{value:(_vm.form.salutation),callback:function ($$v) {_vm.$set(_vm.form, "salutation", $$v)},expression:"form.salutation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"id":"","label":"First Name","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"First Name","vid":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter First Name","required":"","disabled":_vm.isDisabled,"formatter":_vm.length50},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Last Name","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Last Name","vid":"last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-flname","placeholder":"Enter Last Name*","required":"","disabled":_vm.isDisabled,"formatter":_vm.length50},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label-cols-xl":"3","label-cols-md":"4","label":"Date of Birth"}},[_c('div',{staticClass:"calendarIcon"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15","height":"15","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"WindowText","d":"M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"}}),_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}})])]),_c('flat-pickr',{staticClass:"form-control fieldCalendar",attrs:{"config":{ altInput: true,altFormat: 'd/m/Y', dateFormat: 'Y-m-d', maxDate: _vm.maxBirthdayFun },"required":"","disabled":_vm.isDisabled},model:{value:(_vm.form.date_of_birth),callback:function ($$v) {_vm.$set(_vm.form, "date_of_birth", $$v)},expression:"form.date_of_birth"}})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Gender","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Gender","vid":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"black-child-im",attrs:{"label":"title","options":_vm.gender,"placeholder":"Select an option","reduce":function (option) { return option.value; },"disabled":_vm.isDisabled,"id":_vm.disableField},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Organisation","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Organisation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.selectOr,"placeholder":"Select Organisation","disabled":true,"id":_vm.disableField},model:{value:(_vm.selectedOr),callback:function ($$v) {_vm.selectedOr=$$v},expression:"selectedOr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Country","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Country","vid":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.selectCountry,"placeholder":"Select Country","disabled":_vm.isDisabled,"id":_vm.disableField},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Position","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Position","vid":"position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Position","required":"","disabled":_vm.isDisabled,"formatter":_vm.length50},model:{value:(_vm.form.position),callback:function ($$v) {_vm.$set(_vm.form, "position", $$v)},expression:"form.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Department","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Department","vid":"department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Department","required":"","disabled":_vm.isDisabled,"formatter":_vm.length50},model:{value:(_vm.form.department),callback:function ($$v) {_vm.$set(_vm.form, "department", $$v)},expression:"form.department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-lg-flex"},[_c('div',[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Email","label-for":"input-email","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"email","placeholder":"Enter email","required":"","disabled":_vm.isDisabled,"formatter":_vm.length100},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Streets","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Streets","vid":"streets","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"input-streets","placeholder":"Enter Streets","rows":"5","disabled":_vm.isDisabled,"formatter":_vm.length250},model:{value:(_vm.form.streets),callback:function ($$v) {_vm.$set(_vm.form, "streets", $$v)},expression:"form.streets"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Mobile","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Mobile","vid":"mobile","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Mobile","type":"text","readonly":_vm.readonly,"disabled":_vm.isDisabled,"formatter":_vm.length20},on:{"keypress":_vm.isNumber},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}}),(!_vm.isValidPhoneNumber)?[(!_vm.form.mobile)?_c('small',{staticClass:"text-danger"},[_vm._v("The Mobile field is required")]):_vm._e()]:_vm._e(),(_vm.isValidPhoneNumber)?[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]:_vm._e()]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Postal Code","label-for":"input-id","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Postal Code","vid":"Postal Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-code","placeholder":"Enter Postal Code","required":"","disabled":_vm.isDisabled,"formatter":_vm.length20},model:{value:(_vm.form.postalcode),callback:function ($$v) {_vm.$set(_vm.form, "postalcode", $$v)},expression:"form.postalcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"City","label-for":"input-id","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"City","vid":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-city","placeholder":"Enter City","required":"","disabled":_vm.isDisabled,"formatter":_vm.length50},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"State/Province","label-for":"input-id","label-cols-xl":"3","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"State/Province","vid":"state_province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter State/Province","required":"","disabled":_vm.isDisabled,"formatter":_vm.length50},model:{value:(_vm.form.state_province),callback:function ($$v) {_vm.$set(_vm.form, "state_province", $$v)},expression:"form.state_province"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)])],1),_c('b-modal',{ref:"change_modal",attrs:{"id":"modalPopover","title":"Change Password","hide-header-close":"","ok-title":"Save"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{staticClass:"btn-df size-18 black",staticStyle:{"box-shadow":"0px 0px 4px rgba(0, 0, 0, 0.25)"},attrs:{"size":"sm","variant":"static"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"btn-df size-18 black",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.changPass()}}},[_vm._v(" Save ")])]}}],null,true)},[_c('b-row',[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"Old Password","label-cols-xl":"4","label-cols-md":"4","label-class":"text-lg-right bold"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Old Password","vid":"oldpassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Old Password","type":"password","required":""},model:{value:(_vm.formPW.old_password),callback:function ($$v) {_vm.$set(_vm.formPW, "old_password", $$v)},expression:"formPW.old_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"New Password","label-cols-xl":"4","label-cols-md":"4","label-class":"text-lg-right bold"}},[_c('validation-provider',{attrs:{"rules":"required","name":"New Password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"New Password","type":"password","formatter":_vm.length20},on:{"input":_vm.validatePassword},model:{value:(_vm.formPW.new_password),callback:function ($$v) {_vm.$set(_vm.formPW, "new_password", $$v)},expression:"formPW.new_password"}}),(!_vm.isValidPassword)?[_c('small',{staticClass:"text-danger"},[_vm._v("At least 12 characters and contain characters from at least two (2) of the following four (4) categories: Upper case (A through Z), Lower case (a through z), Digits (0-9), Special characters (!,$,#,%, etc)")])]:_vm._e(),(_vm.isValidPassword)?[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]:_vm._e()]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"Confirm New Password","label-cols-xl":"4","label-cols-md":"4","label-class":"text-lg-right bold"}},[_c('validation-provider',{attrs:{"rules":"required|confirmed:password","name":"Confirm New Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-confirmPass","placeholder":"Confirm New Password","type":"password"},model:{value:(_vm.formPW.confirm_new_password),callback:function ($$v) {_vm.$set(_vm.formPW, "confirm_new_password", $$v)},expression:"formPW.confirm_new_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0"},[_c('div',{staticClass:"size-12 bold"},[_vm._v(" At least 12 characters and contain characters from at least two (2) of the following four (4) categories: "),_c('ul',[_c('li',[_vm._v("Upper case (A through Z)")]),_c('li',[_vm._v("Lower case (a through z)")]),_c('li',[_vm._v("Digits (0-9)")]),_c('li',[_vm._v("Special characters (!,$,#,%, etc)")])])])])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }