<template>    
    <div>
        <b-row>
            <b-col md="12">
                <h2 class="mb-2" style="padding-bottom: 5px; padding-top: 9px;">My Profile</h2>
            </b-col>
        </b-row>
        <validation-observer
          ref="profile_list"
          #default="{invalid}" >
            <b-form @submit.prevent class="profile-frm">
                <div class="text-right mb-2">
                    <b-button type="button" variant="primary" class="btn-200 mr-10" v-if="!isEditing" @click="openChangePassword">Change Password</b-button>

                    <b-button type="button" variant="primary" class="btn-df white-color" @click="editAction" v-if="!isEditing">Edit</b-button>

                    <b-button type="button" variant="basic" class="btn-df size-18 black" v-else-if="isEditing" @click="cancelPass" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="save" v-if="isEditing">
                        Save
                    </b-button>
                </div>
                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
                <div class="frm-basic size-16 black-child d-block">              
                      <b-row style="width: 100%; margin: 0;">   
                          <b-col lg="6" class="px-0">             
                              <b-form-group label="ID" label-cols-xl="3"
                                label-cols-md="4" class="input-group-label">
                                    <b-form-input
                                      placeholder="Enter ID"
                                      v-model="form.id"
                                      readonly
                                    ></b-form-input>
                              </b-form-group>
                          </b-col>
                          <b-col lg="6" class="px-0">             
                              <b-form-group label="Salutation" label-cols-xl="3" label-cols-md="4" class="star-required">
                                <validation-provider
                                  #default="{ errors }"
                                  name="Salutation"
                                  vid="salutation"
                                  rules="required"                                  
                                  >
                                    <v-select                            
                                      label="title"
                                      :options="salutation"
                                      placeholder="Select an option"
                                      v-model="form.salutation"
                                      :reduce="option => option.value"
                                      class="black-child-im"
                                      :disabled = "isDisabled"
                                      :id="disableField" 
                                      :formatter="length100"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                          </b-col>
                          <b-col lg="6" class="px-0">
                              <b-form-group id="" label="First Name" label-cols-xl="3"
                                label-cols-md="4" class="star-required input-group-label">
                                <validation-provider
                                  #default="{ errors }"
                                  name="First Name"
                                  vid="first_name"
                                  rules="required"                                  
                                  >
                                    <b-form-input
                                      placeholder="Enter First Name"
                                      v-model="form.first_name"
                                      required
                                      :disabled = "isDisabled"
                                      :formatter="length50"
                                    ></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                          </b-col>
                          <b-col lg="6" class="px-0">
                              <b-form-group label="Last Name" label-cols-xl="3" label-cols-md="4" class="star-required input-group-label">
                                  <validation-provider
                                  #default="{ errors }"
                                  name="Last Name"
                                  vid="last_name"
                                  rules="required"                                  
                                  >
                                      <b-form-input
                                        id="input-flname"
                                        placeholder="Enter Last Name*"
                                        v-model="form.last_name"
                                        required
                                        :disabled = "isDisabled"
                                        :formatter="length50"
                                      ></b-form-input>
                                      <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                              </b-form-group>
                          </b-col>
                          <b-col lg="6" class="px-0">
                              <b-form-group label-cols-xl="3" label-cols-md="4" label="Date of Birth" class="star-required">
                                <div class="calendarIcon">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
                                </div>
                                <flat-pickr
                                  v-model="form.date_of_birth"
                                  class="form-control fieldCalendar"           
                                  :config="{ altInput: true,altFormat: 'd/m/Y', dateFormat: 'Y-m-d', maxDate: maxBirthdayFun }"
                                  required
                                  :disabled = "isDisabled"
                                />
                              </b-form-group>
                          </b-col>
                          <b-col lg="6" class="px-0">             
                              <b-form-group label="Gender" label-cols-xl="3" label-cols-md="4" class="star-required">
                                <validation-provider
                                  #default="{ errors }"
                                  name="Gender"
                                  vid="gender"
                                  rules="required"                                  
                                  >
                                    <v-select
                                      v-model="form.gender"
                                      label="title"
                                      :options="gender"
                                      placeholder="Select an option"
                                      :reduce="option => option.value"
                                      class="black-child-im"
                                      :disabled = "isDisabled"
                                      :id="disableField" 
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                          </b-col>
                          <b-col lg="6" class="px-0">             
                              <b-form-group label="Organisation" label-cols-xl="3" label-cols-md="4" class="star-required">
                                <validation-provider
                                  #default="{ errors }"
                                  name="Organisation"
                                  rules="required"                                  
                                  >
                                    <v-select
                                        v-model="selectedOr"
                                        label="name"
                                        :options="selectOr"
                                        placeholder="Select Organisation"
                                        :disabled = "true"
                                        :id="disableField" 
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                          </b-col>
                          <b-col lg="6" class="px-0">             
                              <b-form-group label="Country" label-cols-xl="3" label-cols-md="4" class="star-required">
                                <validation-provider
                                  #default="{ errors }"
                                  name="Country"
                                  vid="country"
                                  rules="required"                                  
                                  >
                                    <v-select
                                        v-model="selectedCountry"
                                        label="name"
                                        :options="selectCountry"
                                        placeholder="Select Country"
                                        :disabled = "isDisabled"
                                        :id="disableField" 
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                          </b-col>
                          <b-col lg="6" class="px-0">
                              <b-form-group label="Position" label-cols-xl="3" label-cols-md="4" class="star-required input-group-label">
                                <validation-provider
                                  #default="{ errors }"
                                  name="Position"
                                  vid="position"
                                  rules="required"                                  
                                  >
                                  <b-form-input
                                    placeholder="Enter Position"
                                    v-model="form.position"
                                    required
                                    :disabled = "isDisabled"
                                    :formatter="length50"
                                  ></b-form-input>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                          </b-col>
                          <b-col lg="6" class="px-0">
                              <b-form-group label="Department" label-cols-xl="3" label-cols-md="4" class="star-required input-group-label">
                                <validation-provider
                                  #default="{ errors }"
                                  name="Department"
                                  vid="department"
                                  rules="required"                                  
                                  >
                                  <b-form-input
                                    placeholder="Enter Department"
                                    v-model="form.department"
                                    required
                                    :disabled = "isDisabled"
                                    :formatter="length50"
                                  ></b-form-input>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                          </b-col>                    
                      </b-row>
                      <div class="d-lg-flex">
                          <div>
                              <b-col lg="12" class="px-0">
                                  <b-form-group
                                    label="Email"
                                    label-for="input-email" label-cols-xl="3" label-cols-md="4" class="star-required input-group-label">

                                    <validation-provider
                                      #default="{ errors }"
                                      name="Email"
                                      vid="email"
                                      rules="required|email"                                  
                                      >
                                        <b-form-input
                                          type="email"
                                          placeholder="Enter email"
                                          v-model="form.email"
                                          required
                                          :disabled = "isDisabled"
                                          :formatter="length100"
                                        >                                  
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>   
                              </b-col>
                              <b-col lg="12" class="px-0">
                                  <b-form-group label="Streets" label-cols-xl="3" label-cols-md="4" class="star-required">
                                    <validation-provider
                                      #default="{ errors }"
                                      name="Streets"
                                      vid="streets"
                                      rules="required"                                  
                                      >
                                        <b-form-textarea
                                          id="input-streets"
                                          placeholder="Enter Streets"
                                          v-model="form.streets"
                                          rows="5"
                                          :disabled = "isDisabled"
                                          :formatter="length250"
                                        ></b-form-textarea>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                              </b-col>   
                          </div>
                          <div>
                              <b-col lg="12" class="px-0">             
                                  <b-form-group label="Mobile" label-cols-xl="3"
                                    label-cols-md="4" class="star-required input-group-label">
                                    <validation-provider
                                      #default="{ errors }"
                                      name="Mobile"
                                      vid="mobile"
                                      rules="required|min:10"                                  
                                    >
                                      <b-form-input
                                        placeholder="Enter Mobile"
                                        v-model="form.mobile"
                                        type="text"
                                        :readonly="readonly"
                                        :disabled= "isDisabled"
                                        :formatter="length20"       
                                        @keypress ="isNumber"                            
                                      ></b-form-input>
                                      <template v-if="!isValidPhoneNumber">
                                          <small class="text-danger" v-if="!form.mobile">The Mobile field is required</small>
                                      </template>
                                      <template v-if = "isValidPhoneNumber">
                                          <small class="text-danger">{{ errors[0] }}</small>
                                      </template>
                                    </validation-provider>
                                  </b-form-group>
                              </b-col>                    
                              <b-col lg="12" class="px-0">             
                                  <b-form-group label="Postal Code" label-for="input-id" label-cols-xl="3" label-cols-md="4" class="star-required input-group-label">
                                    <validation-provider
                                      #default="{ errors }"
                                      name="Postal Code"
                                      vid="Postal Code"
                                      rules="required"                                  
                                    >
                                      <b-form-input
                                        id="input-code"
                                        placeholder="Enter Postal Code"
                                        v-model="form.postalcode"
                                        required
                                        :disabled = "isDisabled"
                                        :formatter="length20"
                                      ></b-form-input>
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                              </b-col>
                              <b-col lg="12" class="px-0">             
                                  <b-form-group label="City" label-for="input-id" label-cols-xl="3"
                                    label-cols-md="4" class="star-required input-group-label">
                                    <validation-provider
                                      #default="{ errors }"
                                      name="City"
                                      vid="city"
                                      rules="required"                                  
                                    >
                                      <b-form-input
                                        id="input-city"
                                        placeholder="Enter City"
                                        v-model="form.city"
                                        required
                                        :disabled = "isDisabled"
                                        :formatter="length50"
                                      ></b-form-input>
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                              </b-col>
                              <b-col lg="12" class="px-0">             
                                  <b-form-group label="State/Province" label-for="input-id" label-cols-xl="3" label-cols-md="4" class="star-required input-group-label">
                                    <validation-provider
                                    #default="{ errors }"
                                    name="State/Province"
                                    vid="state_province"
                                    rules="required"                                  
                                    >
                                      <b-form-input
                                        placeholder="Enter State/Province"
                                        v-model="form.state_province"
                                        required
                                        :disabled="isDisabled"
                                        :formatter="length50"
                                      ></b-form-input>
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                              </b-col>
                          </div>
                      </div>  
                </div>
                <b-modal id="modalPopover" ref="change_modal" title="Change Password" hide-header-close ok-title="Save">
                    <b-row>
                        <b-col lg="12" class="px-0">
                            <b-form-group label="Old Password" label-cols-xl="4"
                              label-cols-md="4" label-class="text-lg-right bold" class="input-group-label">

                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="Old Password"
                                vid="oldpassword"
                              >
                                  <b-form-input
                                    placeholder="Old Password"
                                    v-model="formPW.old_password"
                                    type="password"
                                    required
                                  ></b-form-input>
                                  <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" class="px-0">
                            <b-form-group label="New Password" label-cols-xl="4"
                              label-cols-md="4" label-class="text-lg-right bold" class="input-group-label">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="New Password"
                                vid="password"
                              >
                                <b-form-input
                                  placeholder="New Password"
                                  v-model="formPW.new_password"
                                  type="password"
                                  :formatter="length20"
                                  @input="validatePassword"
                                ></b-form-input>
                                <template v-if="!isValidPassword">
                                    <small class="text-danger">At least 12 characters and contain characters from at least two (2) of the following four (4) categories: Upper case (A through Z), Lower case (a through z), Digits (0-9), Special characters (!,$,#,%, etc)</small>
                                </template>
                                <template v-if = "isValidPassword">
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </template>
                              </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" class="px-0">
                            <b-form-group label="Confirm New Password" label-cols-xl="4"
                              label-cols-md="4" label-class="text-lg-right bold" class="input-group-label">
                                <validation-provider
                                  #default="{ errors }"
                                  rules="required|confirmed:password"
                                  name="Confirm New Password"
                                >
                                  <b-form-input
                                      id="input-confirmPass"
                                      placeholder="Confirm New Password"
                                      v-model="formPW.confirm_new_password"
                                      type="password"
                                  ></b-form-input>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col class="px-0">
                            <div class="size-12 bold">
                              At least 12 characters and contain characters from at least two (2) of the following four (4) categories:
                              <ul>
                                  <li>Upper case (A through Z)</li>
                                  <li>Lower case (a through z)</li>
                                  <li>Digits (0-9)</li>
                                  <li>Special characters (!,$,#,%, etc)</li>
                              </ul>
                            </div>    
                        </b-col>
                    </b-row>     
                    <template #modal-footer="{ cancel }">
                        <b-button size="sm" variant="static" class="btn-df size-18 black" @click="cancel()" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) ">
                          Cancel
                        </b-button>
                        <b-button size="sm" variant="primary" class="btn-df size-18 black" @click="changPass()">
                          Save
                        </b-button>                        
                    </template>        
                </b-modal>
            </b-form>  
        </validation-observer>     
    </div>
</template>

<script>
    import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    export default {        
        components: {
          flatPickr,
          vSelect,
          ValidationProvider,
          ValidationObserver,
        },
        mixins: [togglePasswordVisibility],
        data() {
          return {                
                form: {},
                formPW: {
                  old_password: null,
                  new_password: null,
                  confirm_new_password: null
                },
                salutation: [
                  { title: 'Mr', value: 0}, 
                  { title: 'Mrs', value: 1 },
                  { title: 'Miss', value: 2 },
                  { title: 'Ms', value: 3 },
                  { title: 'Sir', value: 4 },
                  { title: 'Dr', value: 5 },
                ],
                gender: [
                  { title: 'Male', value: 1 },
                  { title: 'Female', value: 0 }
                ],
                isEditing: false,
                isDisabled: true,
                maxBirthday: null,
                disableField: 'disabled-field',
                readonly: null,

                selectCountry: [], 
                selectedCountry: {},

                selectedOr: {},
                selectOr: [],
                isValidPhoneNumber: true,
                isValidPassword: true,
          }
        },
        computed: {
          maxBirthdayFun(){            
              var d = new Date()
              d.setDate(d.getDate() - 6205)
              return d
          },
        },
        created(){
            this.userProfile()    
            this.listAllCountry()
            this.listOrgan()
        },
        methods: {
          isNumber(event) {
              var ASCIICode = (event.which) ? event.which : event.keyCode

              if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)){
                event.preventDefault();
              }
              else{
                return true;
              }              
          },
          userProfile(){
              var data = JSON.parse(localStorage.getItem("userData"));
              this.$store.dispatch('auth/userProfile', {
                  id: data.id
              })
              .then(response => {

                  this.form = Object.assign(response.data.data, response.data.data.infouser)
                  this.selectedOr = this.form.organisation

                  if(this.form.country) this.getCountryName(this.form.country)
                  // console.log('Profile: ', this.form)
              })
              .catch((error) => {
                console.log(error)
              })
          },
          editAction(){
              this.isEditing = true
              this.isDisabled = false
              this.disableField = ''
          },
          cancelPass(){
            this.isEditing = false
            this.isDisabled = true
            this.disableField= 'disabled-field'
            this.userProfile()
          },
          save() {
            if(!this.form.mobile){
              this.isValidPhoneNumber = false
              return
            }
            this.$refs.profile_list.validate().then(success => {
              if (success) {                        
                  let request = {
                    first_name: this.form.first_name,
                    last_name: this.form.last_name,
                    email: this.form.email,
                    date_of_birth: this.form.date_of_birth,
                    organisation: this.selectedOr ? this.selectedOr.id : null,
                    position: this.form.position,
                    streets: this.form.streets,
                    salutation: this.form.salutation,
                    gender: this.form.gender,
                    country: this.selectedCountry.id,
                    department: this.form.department,
                    mobile: this.form.mobile,
                    city: this.form.city,
                    postalcode: this.form.postalcode,
                    state_province: this.form.state_province,
                  }
                  // console.log('request: ', request)
                  this.$store.dispatch('auth/saveProfile', request)
                  .then(response => {
                      this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: this.form.first_name + ' Updated!',
                            variant: 'success',
                          },
                        },
                        {
                          position: 'top-center'
                        }
                      )
                      this.disableField= 'disabled-field'
                      this.isEditing = !this.isEditing
                      this.isDisabled = true
                      this.refreshTokenLocalStogre()
                  })
                  .catch((error) => {
                      console.log(error)
                      this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: error.response.data.message,
                            variant: 'danger',
                          },
                      },
                      {
                        position: 'top-center'
                      })
                  })
              }
            })
          },
          refreshTokenLocalStogre(){
              this.$store.dispatch('auth/refreshToken')
              .then(response => {
                window.location.reload()
              })
              .catch((error) => {   
                  console.log('error: ', error);
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error.response.data.message,
                        variant: 'danger',
                      },
                  },
                  {
                    position: 'top-center'
                  })
              })
          },        
          changPass(){
            if(!this.isValidPassword){
              return
            }
            this.$refs.profile_list.validate().then(success => {
              if (success) {
                this.$store.dispatch('auth/changPass', this.formPW).then(response => {
                    this.$refs['change_modal'].hide()  
                    this.$toast({
                          component: ToastificationContent,
                          props: {
                              title: 'Password changed!',
                              variant: 'success',
                          },
                    },
                    {
                          position: 'top-center'
                    }) 
                    this.formPW.old_password = null
                    this.formPW.new_password = null
                    this.formPW.confirm_new_password = null                
                })
                .catch((error) => {
                    console.log(error.response.data)   
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: error.response.data.message,
                          variant: 'danger',
                      },
                    },
                    {
                      position: 'top-center'
                    })             
                })
              }
            })
          },
          listOrgan(){
              this.$store
              .dispatch('auth/Client_Organisation_List', {})
              .then(response => {
                  this.selectOr = response.data.data
                  // console.log('this.selectOr: ', this.selectOr)
              })
              .catch((error) => {
                console.log(error)
              })
          },
          listAllCountry(){
              this.$store
              .dispatch('country/fetchCountry')
              .then(response => {
                  this.selectCountry = response.data.data
              })
              .catch((error) => {
                console.log(error)
              })
          },
          getCountryName(id){
              this.$store
              .dispatch('country/getNameCountry', {
                  id: id
              })
              .then(response => {
                  this.selectedCountry = response.data.data
              })
              .catch((error) => {
                console.log(error)
              })
          },          
          length100(e){
             return String(e).substring(0,100);
          },
          length250(e){
            return String(e).substring(0,250);
          },
          length50(e){
            return String(e).substring(0,50);
          },
          length20(e){
            return String(e).substring(0,20);
          },
          openChangePassword(){
            this.$refs['change_modal'].show() 
            this.formPW = {
              old_password: null,
              new_password: null,
              confirm_new_password: null
            }
            this.isValidPassword = true
          },
          validatePassword(val){
            this.isCheckChange = true;
            const charCode = val
            let $count = 0
            if(/[\'^£$%&*()}{@#~?><>,|=_+¬-]/.test(charCode) && charCode){
              $count++
              // console.log($count,'Special characters (!,$,#,%, etc)')
            }
            if (/[0-9]/.test(charCode) && charCode) {
              $count++
              // console.log($count,'Digits (0-9)')
            }
            if (/[a-z]/.test(charCode) && charCode) {
              $count++
              // console.log($count,'Lower case (a through z)')
            }
            if (/[A-Z]/.test(charCode) && charCode) {
              $count++
              // console.log($count,'Upper case (A through Z)')
            }
            if($count > 2 &&  charCode.length > 11){
              this.isValidPassword = true
            }else{
              this.isValidPassword = false
            }
          }
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import "@/assets/scss/_ite_tab_table.scss";

    .modal-dialog .modal-header{
        background-color: transparent;
        justify-content: center;
        padding-top: 0;
    }
    .modal-dialog .modal-header h5{
        font-size: 24px;
        color: black;
    }
    .modal-dialog .modal-footer{
        justify-content: center;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border: 0; 
    }
    .modal-dialog .modal-content{
      padding: 50px 30px; 
    }    
    .modal-footer button{
        border-radius: 5px;
        border: 0;
        max-width: 150px;
        width: 100%;
        height: 50px;
        font-size: 18px;
    }
    .modal-footer button.btn-secondary{
        box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
        border-radius: 5px;
        border: 0;
        color: #442E2E !important;
        background-color: #fff !important;
    }
    .modal-footer button.btn-primary{
        background-color: #8471FF !important;
        color: #fff !important
    }
    .Vue-Toastification__container.top-center .toastification *{
       text-align: center !important;
    }
    @media only screen and (min-width: 1600px){
      .modal-dialog{
          margin-top: 127px; 
      }
    }
    @media only screen and (min-width: 1440px){
        .modal-dialog .col-form-label.col-xl-4 {
            flex: 0 0 33%;
            max-width: 33%;
        }
    }
    @media only screen and (max-width: 1400px){
      .modal-dialog .modal-content {
          padding: 30px;
      }
    }
    @media only screen and (min-width: 992px) {
        .frm-basic div.d-lg-flex > div{
          width: 50%; 
        }
    }
    @media only screen and (min-width: 635px){
        .modal-dialog {
            max-width: 635px;
        }
    }  
</style>